import { createContext, useContext, useState } from 'react';

export const SiteContext = createContext<Partial<Models.Site>>({
	host: 'localhost',
	culture: 'en-US',
	cookieConsentGiven: false,
});

export interface SiteContextProviderProps {
	site: Models.Site;
	children?: React.ReactNode;
}

export const SiteContextProvider: React.FC<SiteContextProviderProps> = ({ site, children }) => {
	const [cookieConsentGiven, setCookieConsentGiven] = useState(site?.cookieConsentGiven);

	return (
		<SiteContext.Provider value={{ ...site, cookieConsentGiven, setCookieConsentGiven }}>
			{children}
		</SiteContext.Provider>
	);
};

export function useSiteContext(): Partial<Models.Site> {
	return useContext(SiteContext);
}

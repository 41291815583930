import { useNavigationContext } from 'application/contexts/NavigationContext';
import { useSiteContext } from 'application/contexts/SiteContext';
import { getUrlPathname } from 'helpers/getUrlPathname/getUrlPathname';
import { useRouter } from 'next/router';
import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

const SegmentContext = createContext<{ segment: string; setSegment: (value: string) => void } | undefined>(undefined);

export const SegmentContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const siteContext = useSiteContext();
	const navContext = useNavigationContext();
	const router = useRouter();
	const [segment, setSegment] = useState<string | null>(null);
	const segmentPages = useMemo(
		() =>
			navContext?.getSegmentPages({
				host: siteContext?.host,
				currentSegmentPath: segment,
			}) ?? [],
		[navContext, siteContext?.host, segment],
	);

	// Set fallback segment to the segment of the first segment page if there are any segment pages
	const fallbackSegment = useMemo(() => {
		return segmentPages.length > 0 ? getUrlPathname(segmentPages[0].url, siteContext?.host) : null;
	}, [segmentPages, siteContext?.host]);

	// Get the segment of the current page from the URL pathname, if it's part of a segment
	const segmentOfCurrentPage = useMemo(() => {
		const currentSegmentPage = segmentPages?.find((item) => {
			const itemPathname = getUrlPathname(item.url, siteContext?.host);

			return router.asPath.startsWith(itemPathname);
		});

		if (currentSegmentPage) {
			return getUrlPathname(currentSegmentPage.url, siteContext?.host);
		} else {
			return null;
		}
	}, [router.asPath, segmentPages, siteContext?.host]);

	// Load state from localStorage on mount
	useEffect(() => {
		const storedSegment = localStorage.getItem('segment');

		if (storedSegment) {
			setSegment(storedSegment);
		}
	}, []);

	// Save to localStorage whenever it changes. This way we can persist the segment across page reloads
	useEffect(() => {
		if (!segment) return;

		localStorage.setItem('segment', segment);
	}, [segment]);

	// Update global segment state when the current URL pathname indicates that the current page is part of a segment
	useEffect(() => {
		if (segmentOfCurrentPage) {
			if (segmentOfCurrentPage === segment) return;

			setSegment(segmentOfCurrentPage);
		} else if (!segment) {
			// If current page is not part of a segment and global segment state is not set, set segment to
			// the one stored in local storage or the fallback segment
			const storedSegment = localStorage.getItem('segment');
			const newSegment = storedSegment || fallbackSegment;

			setSegment(newSegment);
		}
	}, [segmentOfCurrentPage, fallbackSegment, segment, setSegment]);

	return <SegmentContext.Provider value={{ segment, setSegment }}>{children}</SegmentContext.Provider>;
};

export const useSegmentContext = () => {
	const context = useContext(SegmentContext);

	if (!context) {
		throw new Error('useSegmentContext must be used within a SegmentContextProvider');
	}

	return context;
};

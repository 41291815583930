import { DictionaryContextProvider } from 'application/contexts/DictionaryContext';
import { NavigationContextProvider } from 'application/contexts/NavigationContext';
import { PageContextProvider } from 'application/contexts/PageContext';
import { SegmentContextProvider } from 'application/contexts/SegmentContext';
import { SiteContextProvider } from 'application/contexts/SiteContext';
import { NextPage } from 'next';
import type { AppProps as NextAppProps } from 'next/app';
import { StrictMode } from 'react';
import 'ui/styles/globals.scss';

export type DXPContentPage<TProps extends { content?: Content.PageContent }> = NextPage<
	TProps['content'] & Omit<TProps, 'content' | 'session'>
>;

const App: React.FC<NextAppProps> = ({ Component, pageProps }) => {
	const { content, dictionary, navigation, site } = pageProps ?? {};
	const { page } = content ?? {};
	const { culture } = page ?? {};

	return (
		<StrictMode>
			<SiteContextProvider site={site}>
				<DictionaryContextProvider dictionary={dictionary ?? []} culture={culture}>
					<PageContextProvider content={content}>
						<NavigationContextProvider navigation={navigation}>
							<SegmentContextProvider>
								<Component {...content} {...pageProps} />
							</SegmentContextProvider>
						</NavigationContextProvider>
					</PageContextProvider>
				</DictionaryContextProvider>
			</SiteContextProvider>
		</StrictMode>
	);
};

export default App;

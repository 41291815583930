import cx from 'classnames';
import styles from './Link.module.scss';
import { useRouteContext } from 'ui/contexts/RouteContext';

export interface LinkProps extends UI.LinkBaseProps {
	children?: React.ReactNode;
	clickHandler?: (event: React.MouseEvent) => void;
	disableRouteHandler?: boolean; // If true, the routeHandler will not be called and you'll have to handle the routing yourself.
	id?: string;
	color?: 'default' | 'inherit';
}

export const Link: React.FC<LinkProps> = ({
	children,
	className,
	url,
	target,
	title,
	rel,
	clickHandler,
	disableRouteHandler,
	id,
	color = 'default',
	ariaLabel,
}) => {
	const LinkContainer = url ? 'a' : 'span';
	const routeHandler = useRouteContext();

	const handleClick = (event: React.MouseEvent) => {
		if (clickHandler) clickHandler(event);

		if (!disableRouteHandler && url && routeHandler) {
			routeHandler(event, url, target);
		}
	};

	return (
		<LinkContainer
			href={url}
			target={target}
			title={title}
			rel={rel}
			className={cx(styles.Link, color !== 'default' && styles[`Link___color-${color}`], className)}
			onClick={handleClick}
			aria-label={ariaLabel}
			id={id}
		>
			{children}
		</LinkContainer>
	);
};
